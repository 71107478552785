import { Navigate, Outlet, useLocation, useRouteLoaderData } from "react-router-dom";

const UnAuthedRoute = () => {
  const { state } = useLocation();
  const user = useRouteLoaderData('root');

  if (user)
    return <Navigate to={state?.pathname ?? '/'} replace />;

  return <Outlet />;
};

export default UnAuthedRoute;