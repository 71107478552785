import { createContext } from 'react'

export const Theme = {
    LIGHT: 'light',
    DARK: 'dark',
}

Object.freeze(Theme)

export const ThemeContext = createContext({})

export const LOCAL_STORAGE_THEME_KEY = 'theme'