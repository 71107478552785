import './articlePrevNext.scss'
import { getPrevAndNextArticleCid } from "features/blog";
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";


export const ArticlePrevNext = ({id}) => {
    const navigate = useNavigate();

    const [getPrevArticle, setPrevArticle] = useState('');
    const [getNextArticle, setNextArticle] = useState('');

    useEffect(() => {
        (async () => {
            const { prev, next } = await getPrevAndNextArticleCid(id);
    
            setPrevArticle(prev);
            setNextArticle(next);
        })();
    }, [id])

    return <div className="article_prev_next__prevnnext_wrapper">
        {getPrevArticle && <button className="article_prev_next__prev_article"
            onClick={() => navigate(`/b/${getPrevArticle.cid}`)}>
            <div className="article_prev_next__prev_wrapper">
                <p>이전 글</p>
                <p className="article_prev_next__prev_title">{getPrevArticle.title}</p>
            </div>
        </button>}

        {getNextArticle && <button className="article_prev_next__next_article"
            onClick={() => navigate(`/b/${getNextArticle.cid}`)}>
            <div className="article_prev_next__next_wrapper">
                <p>다음 글</p>
                <p className="article_prev_next__next_title">{getNextArticle.title}</p>
            </div>
        </button>}
    </div>
}