import { API } from "shared/api";

export const deleteBlogArticle = async (articleData) => {
    try {
        await API.delete('/blog', articleData);
        
        return true;
    } catch (err) {
        console.log(err)
        return false;
    }
}