import { Link, useRouteError } from 'react-router-dom'

import './fallback.scss'

export const Fallback = () => {
    const err = useRouteError();
    const errLink = `https://ko.wikipedia.org/wiki/HTTP_${err?.status ?? "500"}`;

    return (
        <main className='layout__content'>

            <div role='alert' className='fallback'>
                <Link className='fallback__code' to={errLink} target="_blank">{err?.status ?? err?.response?.status ?? 500}</Link>
                {/* {err?.messageError}  */}
                <h1 className='fallback__title'>무언가 잘못된게 확실합니다.</h1>

                <Link className='fallback__link' to='/' >메인화면으로 돌아가기</Link>
            </div>

        </main>
    )
}