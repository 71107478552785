import { API } from "shared/api";

export const getBlogArticle = async (id) => {
    try {
        const article = await API.get(`/blog/${id}`);
        
        return article.data;
    } catch (err) {
        throw err;
    }
}