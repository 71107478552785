import './articleMarkdown.scss';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math'
import remarkBreaks from 'remark-breaks'
import rehypeKatex from 'rehype-katex'
import rehypeRaw from 'rehype-raw'
import rehypeInlineCodeClassName from 'rehype-inline-code-classname';
import 'katex/dist/katex.min.css'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism'

export const ArticleMarkdown = ({ data }) => {
    return <div className="content">
        <Markdown
            remarkPlugins={[remarkGfm, remarkMath, remarkBreaks]}
            rehypePlugins={[rehypeKatex, rehypeRaw, rehypeInlineCodeClassName]}
            components={{
                code({ children, className, node, ...rest }) {
                    const match = /language-(\w+)/.exec(className || '');

                    return match ? (
                        <SyntaxHighlighter
                            {...rest}
                            PreTag="div"
                            children={String(children).replace(/\n$/, '')}
                            language={match[1]}
                            style={vscDarkPlus}
                        />
                    ) : (
                        <code {...rest} className={className}>
                            {children}
                        </code>
                    )
                },

                blockquote({ children, ...rest }) {
                    return (
                        <blockquote
                            style={{
                                background: "#7afca19b",
                                padding: "1px 15px",
                                borderRadius: "10px",
                            }}
                            {...rest}
                        >
                            {children}
                        </blockquote>
                    );
                },

                a({ children, href, ...rest }) {
                    let id = '';
                    let urlList = ['https://www.youtube.com/watch?v=', 'https://youtu.be/', 'https://www.youtube.com/shorts/'];

                    urlList.forEach((e) => {
                        if (href.startsWith(e))
                            id = href.split(e)[1];
                    })

                    return id
                        ? <>
                            <a href={href}>{children}</a>

                            <div className='youtube_wrapper'>
                                <iframe src={`https://www.youtube.com/embed/${id}`}
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerpolicy="strict-origin-when-cross-origin"
                                    allowfullscreen />
                            </div>
                        </>
                        : <a href={href} {...rest}>{children}</a>
                }
            }}
        >{data}</Markdown >
    </div>
};