import { API } from "shared/api";
import { toast } from 'react-toastify';

export const putProfile = async (profileData) => {
    try {
        await API.put('/auth/profile', profileData);

        return true;
    } catch (err) {
        const message = err.response?.data?.msg ?? "알 수 없는 에러 발생";
        toast.error(message, { toastId: 'edit_profile_false' });

        return false;
    }
}