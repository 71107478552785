import { useRouteLoaderData } from "react-router-dom";
import { useState } from 'react';
import { putProfile } from "features/userAuth";
import { postLogout } from "features/userAuth";

import './profile.scss'

export const Profile = () => {
    const { name, email, image, bio } = useRouteLoaderData('root');
    const [isEditable, setEditable] = useState(false);
    const [inputName, setInputName] = useState(name);
    const [inputEmail, setInputEmail] = useState(email);
    const [inputBio, setInputBio] = useState(bio);

    // bio: "안녕하세요."
    // email: "aheui@kakao.com"
    // id: "kinetic27"
    // image: "https://www.gravatar.com/avatar/d5718ac72bd5a603766369912a4f6851?d=identicon&s=128"
    // join_time: "2024-07-17T02:21:02.000Z"
    // name: "kinetic27"
    // permissions: {blog: {…}, main: {…}}

    const submitProfile = async () => {
        setEditable(false);

        const profileData = {
            'name': inputName,
            'email': inputEmail,
            'bio': inputBio,
        };

        await putProfile(profileData);
    }

    const goGravatar = () => {
        alert('Gravatar에서 계정과 같은 이메일로 로그인 후 프로필을 편집하면 반영됩니다.');
        window.open('https://ko.gravatar.com/');
    }

    const doLogout = async () => {
        if (await postLogout()) {
            window.location.replace('/');
        }
    }

    return (
        <div className='profile'>
            <article className="layout__article">
                <div className="profile__info">
                    <div className='profile__info_wrapper'>

                        {!isEditable
                            ? <img className="profile__image" src={image} alt="" />
                            : <img className="profile__image profile__image_edit" src={image} alt="" onClick={goGravatar} />}

                        <div className="profile__detail_wrapper">
                            {!isEditable
                                ? <h2 className="profile__name">{inputName}</h2>
                                : <input className='profile__input' type='text' placeholder={inputName}
                                    spellCheck='false' maxLength='30' required defaultValue={inputName}
                                    onChange={(e) => setInputName(e.target.value)} />}

                            {!isEditable
                                ? <h3 className="profile__email">{inputEmail}</h3>
                                : <input className='profile__input' type='text' placeholder={inputEmail}
                                    spellCheck='false' maxLength='320' required defaultValue={inputEmail}
                                    onChange={(e) => setInputEmail(e.target.value)} />}

                            <hr className="profile__divider" />
                            <textarea className="profile__bio" readOnly={!isEditable} defaultValue={inputBio}
                                onChange={(e) => setInputBio(e.target.value)} />


                            <div className="profile__btn_wrapper">
                                {!isEditable && <button className="profile__edit_btn" onClick={() => setEditable(true)}>프로필 편집</button>}
                                {isEditable && <button className="profile__submit_btn" onClick={submitProfile}>프로필 저장</button>}

                                <button className="profile__logout_btn" onClick={doLogout}>로그아웃</button>

                            </div>
                        </div>
                    </div>

                </div>
            </article>
        </div>
    )
}