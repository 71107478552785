import { useEffect } from "react";
import { Terminal as xTerm } from "@xterm/xterm";
import { ClipboardAddon } from '@xterm/addon-clipboard';
import { FitAddon } from '@xterm/addon-fit';
import { useNavigate } from "react-router-dom";

import "@xterm/xterm/css/xterm.css";
import './terminal.scss'

// https://en.wikipedia.org/wiki/ANSI_escape_code#CSI_(Control_Sequence_Introducer)_sequences

export const Terminal = () => {
    const navigate = useNavigate();

    useEffect(() => {
        class xTerminal {
            constructor(element) {
                this.wrapper = element;
                this.terminal = new xTerm();

                this.setOptions();
                this.loadAddons();
                this.loadTerminal();
                this.addKeyListener();
                this.addEventListeners();

                this.printDefaultMsg();
                this.addPrefix();
            }

            setOptions() {
                this.version = '1.0';
                this.terminal.options.fontSize = 16;
            }

            loadAddons() {
                const clipboardAddon = new ClipboardAddon();
                this.terminal.loadAddon(clipboardAddon);

                this.fitAddon = new FitAddon();
                this.terminal.loadAddon(this.fitAddon);
            }

            loadTerminal() {
                this.buffer = [];
                this.cmdRecords = [];
                this.cmdController = 0;
                this.lastBuffer = '';
                this.terminal.open(this.wrapper);
                // this.fitAddon.fit();
            }

            addKeyListener() {
                this.buffer = '';

                this.terminal.onData((e) => {
                    // Enter Key
                    if (e === '\r') {
                        this.println('');
                        this.executeCommand(this.buffer.trim());
                        this.buffer = ''

                        this.cmdController = this.cmdRecords.length;
                        return;
                    }

                    // Backspace Key
                    if (e === '\x7F') {
                        if (this.buffer.length > 0)
                            this.terminal.write('\b \b');

                        this.buffer = this.buffer.slice(0, -1);

                        return;
                    }

                    // Up Arrow
                    if (e === '\x1B[A') {
                        if (this.cmdController <= 0) return;
                        this.cmdController -= 1;

                        const commandIndex = Math.max(0, this.cmdController)
                        const lastCommand = decodeURI(this.cmdRecords[commandIndex]);

                        this.print('\x1B[2K\r');
                        this.addPrefix();
                        this.print(lastCommand);

                        this.buffer = lastCommand;

                        return;
                    }

                    // Buttom Arrow
                    if (e === '\x1B[B') {
                        if (this.cmdController >= this.cmdRecords.length) return;
                        this.cmdController += 1;

                        const commandIndex = Math.min(this.cmdRecords.length, this.cmdController)
                        const lastCommand = decodeURI(this.cmdRecords.concat([this.lastBuffer])[commandIndex]);

                        this.print('\x1B[2K\r');
                        this.addPrefix();
                        this.print(lastCommand);

                        this.buffer = lastCommand;

                        return;
                    }

                    // side Arrow Key disable
                    if (['\x1B[C', '\x1B[D'].includes(e)) return;

                    // esc, paste, tab disable
                    if (['', '', '\t'].includes(e) || e.indexOf('') > -1) return;

                    // limit terminal text length
                    if (this.buffer.length + e.length >= 78) return;

                    // when paste long text
                    if (e.length > 1) {
                        const formattedInput = e.replaceAll(String.fromCharCode(13), '\r\n');

                        this.print(formattedInput);
                        this.buffer += formattedInput;

                        return;
                    }

                    // else
                    if (20 <= e.charCodeAt(0) && e.charCodeAt(0) <= 255) {
                        this.terminal.write(e);
                        this.buffer += e;
                    }

                    // add last command
                    if (this.cmdController === this.cmdRecords.length)
                        this.lastBuffer = this.buffer;

                });
            }

            addEventListeners() {
                this.resize = () => this.fitAddon.fit();

                // window.addEventListener("resize", this.resize);
            }

            removeEventListeners() {
                // window.removeEventListener("resize", this.resize);
            }

            executeCommand(input) {
                if (input === '') return this.addPrefix();

                // input = input.replace(/^[ 　\s]*|[ 　\s]*$/g, '');
                // // eslint-disable-next-line
                // var regex = /^([A-Z0-9ㄱ-ㅎㅏ-ㅣ가-힣_\-.\\\/\[\]\{\}\(\)\?\!@#$%^&*.,:;''`~|]+) ?(.+)?/i;
                // var match = input.match(regex);
                // if (!match) return;

                const args = input.split(' ');
                const cmd = args[0];
                const params = args.slice(1).join(' ');

                switch (cmd.toLowerCase()) {
                    case 'ascii':
                        this.print('§a');
                        this.println('Dec Hex    Dec Hex    Dec Hex  Dec Hex  Dec Hex  Dec Hex   Dec Hex   Dec Hex');
                        this.println('  0 00 NUL  16 10 DLE  32 20    48 30 0  64 40 @  80 50 P   96 60 `  112 70 p');
                        this.println('  1 01 SOH  17 11 DC1  33 21 !  49 31 1  65 41 A  81 51 Q   97 61 a  113 71 q');
                        this.println('  2 02 STX  18 12 DC2  34 22 "  50 32 2  66 42 B  82 52 R   98 62 b  114 72 r');
                        this.println('  3 03 ETX  19 13 DC3  35 23 #  51 33 3  67 43 C  83 53 S   99 63 c  115 73 s');
                        this.println('  4 04 EOT  20 14 DC4  36 24 $  52 34 4  68 44 D  84 54 T  100 64 d  116 74 t');
                        this.println('  5 05 ENQ  21 15 NAK  37 25 %  53 35 5  69 45 E  85 55 U  101 65 e  117 75 u');
                        this.println('  6 06 ACK  22 16 SYN  38 26 &  54 36 6  70 46 F  86 56 V  102 66 f  118 76 v');
                        this.println('  7 07 BEL  23 17 ETB  39 27 \'  55 37 7  71 47 G  87 57 W  103 67 g  119 77 w');
                        this.println('  8 08 BS   24 18 CAN  40 28 (  56 38 8  72 48 H  88 58 X  104 68 h  120 78 x');
                        this.println('  9 09 HT   25 19 EM   41 29 )  57 39 9  73 49 I  89 59 Y  105 69 i  121 79 y');
                        this.println(' 10 0A LF   26 1A SUB  42 2A *  58 3A :  74 4A J  90 5A Z  106 6A j  122 7A z');
                        this.println(' 11 0B VT   27 1B ESC  43 2B +  59 3B ;  75 4B K  91 5B [  107 6B k  123 7B {');
                        this.println(' 12 0C FF   28 1C FS   44 2C ,  60 3C <  76 4C L  92 5C \\  108 6C l  124 7C |');
                        this.println(' 13 0D CR   29 1D GS   45 2D -  61 3D =  77 4D M  93 5D ]  109 6D m  125 7D }');
                        this.println(' 14 0E SO   30 1E RS   46 2E .  62 3E >  78 4E N  94 5E ^  110 6E n  126 7E ~');
                        this.println(' 15 0F SI   31 1F US   47 2F /  63 3F ?  79 4F O  95 5F _  111 6F o  127 7F DEL');

                        break;

                    case 'blog':
                        let link = '/b';
                        if (args[1]) link += '?page=' + args[1];
                        navigate(link);

                        break;

                    case 'cat':
                    case 'ls':
                        this.println('                  /| _ ╱|、  ');
                        this.println('                 ( •̀ㅅ •́ )');
                        this.println('              ＿ノ ヽ ノ＼＿ ');
                        this.println('            /　`/ ⌒Ｙ⌒ Ｙ　 \\');
                        this.println('           ( 　(三ヽ人　 /　|');
                        this.println('          |　ﾉ⌒＼ ￣￣ヽ　 ノ');
                        this.println('          ヽ＿＿＿＞､＿＿／');
                        this.println('               ｜( 王 ﾉ〈 ');
                        this.println('               /ﾐ`ー―彡 \\ ');
                        this.println('              |╰       ╯ |  ');
                        this.println('  ∧ ,,,∧      |    /\\    |');
                        this.println('(  ̳• · • ̳)    |   /  \\   |  ');
                        this.println('/    づ♡     |   /    \\  |   ');
                        this.println();
                        this.println('§aWe don\'t have a file system here. Please look at the cute cat instead');

                        break;

                    case 'cls':
                    case 'clear':
                        this.terminal.clear();

                        break;

                    case 'echo':
                        this.println(params);

                        break;

                    case 'hello':
                    case 'hello_world':
                        this.printDefaultMsg();

                        break;

                    case 'help':
                    case '?':
                        if (args.length === 1) {
                            // this.println(`Type §a"HELP [Command]" §xif you need more information about a specific command.`);
                            // this.println();
                            this.println('§eASCII\t§xShow ascii table');
                            this.println('§eBLOG [page]\t§xMove to the Blog page');
                            this.println('§eCAT\t\t§xMeow');
                            this.println('§eCLEAR\t\t§xClear the terminal screen.');
                            this.println('§eECHO\t\t§xDisplay a text on the screen.');
                            this.println('§eHELLO_WORLD\t§xWelcomes you.');
                            this.println('§eTIME\t\t§xShow now time');
                            this.println('§eTREE\t\t§xShow tr?ee');
                            this.println('§eVERSION\t\t§xShow Terminal Version');
                        }

                        break;

                    case 'time':
                        const TIME_ZONE = 9 * 60 * 60 * 1000; // GMT +9
                        const date = new Date();
                        this.println('§a' + new Date(date.getTime() + TIME_ZONE).toISOString().replace('T', ' ').slice(0, -5));

                        break;

                    case 'tree':
                        this.println('');
                        this.println('§2⠀⠀⢀⣴⣶⣤⣾⣿⣿⣦⣶⡄⠀⠀⠀ ');
                        this.println('§2⠀⠀⢸⣿⣿⣿⣿⣿⣿⣿⣿⣿⣦⠀⠀ ');
                        this.println('§2⠀⠰⣾⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡟⠀ ');
                        this.println('§2⠀⢸⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⠀');
                        this.println('§2⠀⠈⢿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡿⠋⠀');
                        this.println('§2⠀⠀⠀⠈⠛⠻⠟⠉§g⣭⡍⠀⠀⠀⠀⠀');
                        this.println('§2⠀⠀⠀⣀⠀⠀§g⢰⣾⣿⡇⠀⠀⠀⠀⠀');
                        this.println('§2⢀⣾⣿⣿⡇§g⠀⢸⣿⣿⡇⠀⠀⠀⠀⠀');
                        this.println('§2⠈⠙⣿⡿§g⠻⣷⣼⣿⣿⡇⠀§2⣤⣤⣤⡀');
                        this.println('§g⠀⠀⠀⠀⠀⠀⢹⣿⣿⡇⣀§2⣿⣿⣿⣧');
                        this.println('§g⠀⠀⠀⠀⠀⠀⢸⣿⣿⣿⠟⠁§2⠹⠟⠁');
                        this.println('§g⠀⠀⠀⠀⠀⢀⣼⣿⣿⣇⠀⠀⠀⠀⠀');
                        this.println('§g⠀⠀⠀⢀⣤⣿⣿⣿⣿⣿⣷⣤⠀⠀⠀');
                        this.println('');
                        this.println('§xJust §2Tree. §xIsn\'t that what you wanted?');

                        break;

                    case 'v':
                    case 'version':
                        this.println(`§6Kinetic Web Terminal §d[Version ${this.version}]`);
                        
                        break;

                    default:
                        this.println(`§4Command not found§6(use "help")§4: §x${cmd}`);
                }

                this.addToCommandRecords(input);
                this.addPrefix();
            }

            addToCommandRecords(cmd) {
                if (this.cmdRecords.at(-1) !== encodeURI(cmd)) {
                    this.cmdRecords.push(encodeURI(cmd));
                    this.cmdController++;
                }
            }

            print(str) {
                this.terminal.write(this.colorParse(str));
            }

            println(str = '') {
                this.terminal.write(this.colorParse(str + '\r\n'));
            }

            colorParse(str) {
                const prefix = "§";
                const color = {
                    '0': '\x1B[30m', // black
                    '1': '\x1B[34m', // dark_blue
                    '2': '\x1B[32m', // dark_green
                    '3': '\x1B[36m', // dark_aqua
                    '4': '\x1B[31m', // dark_red
                    '5': '\x1B[35m', // dark_purple
                    '6': '\x1B[33m', // gold
                    '7': '\x1B[37m', // gray
                    '8': '\x1B[90m', // dark_gray
                    '9': '\x1B[94m', // blue
                    'a': '\x1B[92m', // green
                    'b': '\x1B[96m', // aqua
                    'c': '\x1B[91m', // red
                    'd': '\x1B[95m', // light_purple
                    'e': '\x1B[93m', // yellow
                    'f': '\x1B[97m', // white
                    'g': '\x1B[38;5;94m', // brown 
                    'x': '\x1B[0m', // noColor
                }

                let resultStr = str;

                for (let key in color) {
                    const value = color[key];

                    resultStr = resultStr.replaceAll(prefix + key, value);
                }

                return resultStr;
            }

            addPrefix() {
                this.print(`\x1B[33m$ \x1B[0m`);
            }

            printDefaultMsg() {
                this.print("§a");
                this.println("==========================================================");
                this.println("                                                          ");
                this.println("  ██╗  ██╗ ███████╗ ██╗     ██╗     ██████╗               ");
                this.println("  ██║  ██║ ██╔════╝ ██║     ██║     ██╔═══██╗             ");
                this.println("  ███████║ █████╗   ██║     ██║     ██║   ██║             ");
                this.println("  ██╔══██║ ██╔══╝   ██║     ██║     ██║   ██║             ");
                this.println("  ██║  ██║ ███████╗ ██████╗ ██████╗ ╚██████╔╝             ");
                this.println("                                                          ");
                this.println("           ██╗    ██╗  ██████╗  ██████╗  ██╗     ██████╗  ");
                this.println("           ██║    ██║ ██╔═══██╗ ██╔══██╗ ██║     ██╔══██╗ ");
                this.println("           ██║ █╗ ██║ ██║   ██║ ██████╔╝ ██║     ██║  ██║ ");
                this.println("           ██║███╗██║ ██║   ██║ ██╔══██╗ ██║     ██║  ██║ ");
                this.println("  ███████╗ ╚███╔███╔╝ ╚██████╔╝ ██║  ██║ ██████╗ ██████╔╝ ");
                this.println("                                                          ");
                this.println("==========================================================");
                this.println("                                                          ");
                this.println(`§6Kinetic Web Terminal §d[Version ${this.version}]        `);
                this.println("§b(c) 2024 Kinetic. All rights reserved.                  ");
                this.println("§7Type §a\"help\" §xor §a\"?\" §7to check the command description ");
                this.print("§x");
                this.print('\r\n');
            }
        }

        const terminal = new xTerminal(document.querySelector(".xterm"))


        return () => terminal.removeEventListeners();
    }, [navigate]);

    return <div className="xterm" />
}