import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { postLogin } from 'features/userAuth';
import { useSearchParams } from "react-router-dom";

import './login.scss'

export const Login = () => {
    const [searchParams] = useSearchParams();
    const { register, handleSubmit, setFocus } = useForm();

    useEffect(() => {
        setTimeout(() => setFocus('id'), 500);
    }, [setFocus]);

    const submitLogin = async (data) => {
        if (await postLogin(data)) {
            // console.log(searchParams.get('r'));
            window.location.replace(searchParams.get('r') ?? '/');
        }
    }

    return (
        <div className='login'>
            <form className='login__form' onSubmit={handleSubmit(submitLogin)}>
                <div className='login__title-wrapper'>
                    <h2 className='login__title'>당신을 기다렸어요!</h2>
                    <h3 className='login__subtitle'>로그인하여 다양한 서비스에 접근해보세요!</h3>
                </div>

                <div className='login__input-wrapper'>
                    <input {...register('id')}
                        className='login__input' type='text' placeholder='아이디'
                        spellCheck='false' minLength='5' maxLength='20' required />

                    <input {...register('password')}
                        className='login__input' type='password' placeholder='비밀번호'
                        spellCheck='false' minLength='8' maxLength='50' required />
                </div>

                <button className='login__btn'>로그인</button>
            </form>
        </div>

    )
}