import { API } from "shared/api";

export const getUser = async () => {
    try {
        const user = await API.get('/auth/status');

        return user.data;
    } catch (err) {
        return null;
    }
}