import { useRouteLoaderData, useNavigate, useLoaderData } from "react-router-dom";
import { useForm } from 'react-hook-form';
import './blogWrite.scss'
import { useEffect, useState, useRef } from "react";
import { ArticleMarkdown } from "features/blog/ui/articleMarkdown";
import { ArticleMarkdownEditor } from "features/blog/ui/articleMarkdownEditor";
import { UploadImage } from "features/image";
import { postBlogArticle, editBlogArticle, deleteBlogArticle } from "features/blog";
import { toast } from 'react-toastify';

export const BlogWrite = () => {
    const user = useRouteLoaderData('root');
    const article_data = useLoaderData();
    const editmode = article_data !== undefined;

    const navigate = useNavigate();

    const blogPerms = user?.permissions?.blog;

    useEffect(() => {
        if (!editmode && !blogPerms?.write) {
            alert('페이지 열람 권한이 없습니다.');

            return navigate(-1);
        }
    }, [user, navigate, blogPerms, editmode]);

    const [textValue, setTextValue] = useState('');
    const { register, handleSubmit, setFocus, setValue } = useForm();
    const thumbnail_image = useRef();

    useEffect(() => {
        setTimeout(() => setFocus('id'), 500);

        if (editmode) {
            const { category, content, title, thumbnail, cid, author } = article_data;

            if ((blogPerms?.write && user.id !== author) && !blogPerms?.admin) {
                alert('글 수정 권한이 없습니다.');

                return navigate(-1);
            }

            setValue('title', title);
            setValue('cid', cid);
            setValue('category', category);
            setValue('thumbnail', thumbnail);

            thumbnail_image.current.src = thumbnail;
            setTextValue(content);
        }
    }, [article_data, editmode, navigate, user, blogPerms, setTextValue, setFocus, setValue]);

    const submitArticle = async (data) => {
        data['text'] = textValue;

        if (!data?.thumbnail) {
            const firstImg = /!\[.*\]\((?<link>.*)\)/.exec(textValue);

            setValue('thumbnail', firstImg?.groups.link);

            if (firstImg?.groups.link)
                toast.info("첫 이미지를 썸네일로 설정했습니다.", { toastId: 'article_set_first_img_to_thumbnail' });
            else
                toast.error("썸네일로 사용할 이미지를 본문에서 찾을 수 없습니다.", { toastId: 'article_set_first_img_err' });

            return;
        }

        if (editmode) {
            data['id'] = article_data.id;

            const isSuccessEditArticle = await editBlogArticle(data);

            if (isSuccessEditArticle)
                navigate('/b/' + data.id);
            else
                toast.error("글 수정을 실패했습니다", { toastId: 'article_edit_false' });
        } else {
            const isSuccessPostArticle = await postBlogArticle(data);

            if (isSuccessPostArticle)
                navigate('/b/');
            else
                toast.error("글 쓰기를 실패했습니다", { toastId: 'article_post_false' });
        }
    }

    const deleteArticle = async () => {
        if (window.confirm('정말로 삭제하시겠습니까?')) {
            const isSuccessDeleteArticle = await deleteBlogArticle({ data: { 'id': article_data.id } });

            if (isSuccessDeleteArticle)
                navigate('/b/');
            else
                toast.error("글 삭제를 실패했습니다", { toastId: 'article_delete_false' });
        }
    }

    return (
        <div className='editor'>
            <article className="layout__article">
                <form className='editor__form' onSubmit={handleSubmit(submitArticle)}>
                    <input {...register('title')} name="title"
                        className='editor__input editor__title' type='text' placeholder='제목'
                        spellCheck='false' maxLength='30' required />

                    <ArticleMarkdownEditor data={textValue} setData={setTextValue} />

                    <details>
                        <summary>실제 적용 미리보기!</summary>
                        <ArticleMarkdown data={textValue} />
                    </details>

                    <div className="editor__subinfo_wrapper">
                        <input {...register('cid')} name="cid"
                            className='editor__input editor__cid' type='text' placeholder='제목 URL'
                            spellCheck='false' maxLength='30' required pattern="^[a-zA-Z0-9\-_.~]+$" />

                        <input {...register('category')} name="category"
                            className='editor__input editor__category' type='text' placeholder='카테고리'
                            spellCheck='false' maxLength='30' required />
                    </div>

                    <input {...register('thumbnail')} name="thumbnail"
                        className='editor__input editor__thumbnail' type='text' placeholder='썸네일 링크 (붙여넣기로 업로드)'
                        spellCheck='false' pattern="https:\/\/.*"
                        onPaste={async (event) => {
                            const tmpText = event?.clipboardData.getData('Text');
                            const tmpHtml = event?.clipboardData.getData('text/html');

                            if (!tmpText && !tmpHtml) {
                                const url = await UploadImage(event.clipboardData);

                                setValue('thumbnail', url[0]);
                                thumbnail_image.current.src = url[0];
                                thumbnail_image.current.style.display = 'inline';
                            }
                        }}
                        onDrop={async (event) => {
                            const tmpText = event?.dataTransfer.getData('Text');
                            const tmpHtml = event?.dataTransfer.getData('text/html');

                            if (!tmpText && !tmpHtml) {
                                const url = await UploadImage(event.dataTransfer);
                                console.log(url)
                                // 이미지 서버 만들어야함, 429 too many request 뜸
                                setValue('thumbnail', url[0]);
                                thumbnail_image.current.src = url[0];
                                thumbnail_image.current.style.display = 'inline';
                            }
                        }}
                        onChange={(e) => {
                            thumbnail_image.current.src = e.target.value;
                            thumbnail_image.current.style.display = 'inline';
                        }} />

                    <img ref={thumbnail_image} className="editor__thumbnail_image"
                        onError={e => e.target.style.display = 'none'}
                        src="" alt="썸네일 이미지" />

                    <div className="editor__submit_wrapper">
                        {editmode && <button className='editor__delete editor__btn' type="button" onClick={deleteArticle}>삭제하기</button>}
                        {editmode && <button className='editor__edit editor__btn' type="submit">수정하기</button>}
                        {!editmode && <button className='editor__submit editor__btn' type="submit">작성하기</button>}
                    </div>
                </form>
            </article>
        </div>
    )
}