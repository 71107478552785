// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../shared/assets/fonts/nasalization-rg.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Gothic+A1&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable-dynamic-subset.min.css);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Jersey+20+Charted&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Nasalization";
  font-weight: normal;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");
}
.md__wrapper {
  height: 100vh;
  width: 100%;
}
.md__wrapper .w-md-editor {
  height: 100% !important;
}
.md__wrapper .code-line .token {
  font-size: unset !important;
  line-height: unset !important;
}
.md__wrapper pre.w-md-editor-text-pre > code,
.md__wrapper .w-md-editor-text-input,
.md__wrapper .w-md-editor-text-input > textarea {
  font-size: 1.3rem !important;
  line-height: 1.618 !important;
  border: 0px !important;
}
.md__wrapper .w-md-editor-toolbar button {
  height: 3rem;
  width: 3rem;
}
.md__wrapper .w-md-editor-toolbar svg {
  height: 1.5rem;
  width: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/styles/font.scss","webpack://./src/features/blog/ui/articleMarkdownEditor.scss"],"names":[],"mappings":"AAAA;EACI,2BAAA;EACA,mBAAA;EACA,+DAAA;ACIJ;AAHA;EACI,aAAA;EACA,WAAA;AAKJ;AAHI;EACI,uBAAA;AAKR;AAFI;EACI,2BAAA;EACA,6BAAA;AAIR;AADI;;;EAGI,4BAAA;EACA,6BAAA;EACA,sBAAA;AAGR;AACQ;EACI,YAAA;EACA,WAAA;AACZ;AAEQ;EACI,cAAA;EACA,aAAA;AAAZ","sourcesContent":["@font-face {\n    font-family: \"Nasalization\";\n    font-weight: normal;\n    src: url(\"/shared/assets/fonts/nasalization-rg.otf\") format('opentype');\n  }\n\n@import url('https://fonts.googleapis.com/css2?family=Gothic+A1&display=swap');\n$gothic-a1: \"Gothic A1\", sans-serif;\n\n@import url(\"https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable-dynamic-subset.min.css\");\n\n$pretendard: \"Pretendard Variable\", sans-serif;\n$nasalization: \"Nasalization\", sans-serif;\n\n\n@import url('https://fonts.googleapis.com/css2?family=Jersey+20+Charted&display=swap');\n\n$jersey-20-charted: \"Jersey 20 Charted\", sans-serif;\n","@import \"/src/app/styles/font.scss\";\n@import \"/src/app/styles/color.scss\";\n@import \"/src/app/styles/breakpoints.scss\";\n\n.md__wrapper {\n    height: 100vh;\n    width: 100%;\n\n    .w-md-editor {\n        height: 100% !important;\n    }\n\n    .code-line .token {\n        font-size: unset !important;\n        line-height: unset !important;\n    }\n\n    pre.w-md-editor-text-pre>code,\n    .w-md-editor-text-input,\n    .w-md-editor-text-input>textarea {\n        font-size: 1.3rem !important;\n        line-height: 1.618 !important;\n        border: 0px !important;\n    }\n\n    .w-md-editor-toolbar {\n        button {\n            height: 3rem;\n            width: 3rem;\n        }\n\n        svg {\n            height: 1.5rem;\n            width: 1.5rem;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
