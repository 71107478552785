// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  text-rendering: optimizeSpeed;
  overflow-x: hidden;
}

body,
html {
  width: 100%;
}

#root {
  min-height: 100vh;
}

* {
  margin: 0;
  padding: 0;
  border: none;
  border-spacing: 0;
  outline: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

ol,
ul {
  list-style: none;
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
h5,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 400;
}

input,
button {
  outline: none;
}

a {
  text-decoration: none;
  color: inherit;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.3rem;
}

h4 {
  font-size: 1.7rem;
}

h5 {
  font-size: 1.4rem;
}

h6 {
  font-size: 1rem;
}

ol {
  list-style: decimal;
  padding-left: 1.1rem;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EAGA,uBAAA;AADJ;;AAIA;EACI,SAAA;EACA,UAAA;EACA,6BAAA;EACA,kBAAA;AADJ;;AAMA;;EAGI,WAAA;AAJJ;;AAQA;EACI,iBAAA;AALJ;;AAQA;EACI,SAAA;EACA,UAAA;EACA,YAAA;EACA,iBAAA;EACA,aAAA;AALJ;;AAQA;;;EAGI,sBAAA;AALJ;;AAQA;;EAEI,gBAAA;EACA,UAAA;AALJ;;AAQA;;;;;;;;;;;;;;;EAeI,SAAA;AALJ;;AAQA;;;;;EAKI,gBAAA;AALJ;;AAQA;;EAEI,aAAA;AALJ;;AAQA;EACI,qBAAA;EACA,cAAA;AALJ;;AAQA;EACI,eAAA;AALJ;;AAQA;EACI,eAAA;AALJ;;AAQA;EACI,iBAAA;AALJ;;AAQA;EACI,iBAAA;AALJ;;AAQA;EACI,iBAAA;AALJ;;AAQA;EACI,eAAA;AALJ;;AAQA;EACI,mBAAA;EACA,oBAAA;AALJ","sourcesContent":["html {\n    font-size: 16px;\n    // font-size: clamp(8px, 2.5vw, 16px);\n    // font-size: calc(100vw / 200 + 16px);\n    scroll-behavior: smooth;\n}\n\nbody {\n    margin: 0;\n    padding: 0;\n    text-rendering: optimizeSpeed;\n    overflow-x: hidden;\n    // min-width: fit-content;\n    // min-height: 100vh;\n}\n\nbody,\nhtml {\n    // height: 100vh;\n    width: 100%;\n    // overflow-x: hidden;\n}\n\n#root {\n    min-height: calc(100vh);\n}\n\n* {\n    margin: 0;\n    padding: 0;\n    border: none;\n    border-spacing: 0;\n    outline: none;\n}\n\n*,\n*::before,\n*::after {\n    box-sizing: border-box;\n}\n\nol,\nul {\n    list-style: none;\n    padding: 0;\n}\n\nbody,\nh1,\nh2,\nh3,\nh4,\nh5,\np,\nul,\nol,\nli,\nfigure,\nfigcaption,\nblockquote,\ndl,\ndd {\n    margin: 0;\n}\n\nh1,\nh2,\nh3,\nh4,\nh5 {\n    font-weight: 400;\n}\n\ninput,\nbutton {\n    outline: none;\n}\n\na {\n    text-decoration: none;\n    color: inherit;\n}\n\nh1 {\n    font-size: 4rem;\n}\n\nh2 {\n    font-size: 3rem;\n}\n\nh3 {\n    font-size: 2.3rem;\n}\n\nh4 {\n    font-size: 1.7rem;\n}\n\nh5 {\n    font-size: 1.4rem;\n}\n\nh6 {\n    font-size: 1rem;\n}\n\nol {\n    list-style: decimal;\n    padding-left: 1.1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
