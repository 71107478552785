// import { Link } from 'react-router-dom'
import clsx from 'clsx'
// import Email from 'shared/assets/icons/email.svg?react'
// import Github from 'shared/assets/icons/github.svg?react'
// import Telegram from 'shared/assets/icons/telegram.svg?react'

import './footer.scss'

export const Footer = ({ className }) => {

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    return (
        <div className={clsx('footer', className)}>
            <div className='footer__wrapper _container'>
                <div className='footer__col'>
                    <div className='footer__list'>
                        <h3 className='footer__header' onClick={scrollToTop}>KINEWEB</h3>
                        <h5 className='footer__copyright'>© 2024 Kinetic27</h5>
                    </div>
                </div>

                <div className='footer__col'>
                    <ul className='footer__list'>
                        <li>
                            <a
                                href='mailto:aheui@kakao.com'
                                target='_blank'
                                rel='noreferrer'>
                                이메일
                            </a>
                        </li>
                        <li>
                            <a
                                href='https://github.com/kinetic27'
                                target='_blank'
                                rel='noreferrer'>
                                깃허브
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}