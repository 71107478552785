import { API } from "shared/api";

export const getBlogArticles = async (value, count = false, searchURL = window.location.search) => {
    try {
        if (count) {
            const serachParams = new URLSearchParams(searchURL);
            serachParams.set('count', 'true');
            searchURL = '?' + serachParams.toString();

            if (searchURL.indexOf('undefined=&') === 1)
                searchURL = '?' + searchURL.slice('?undefined=&'.length);
        }

        const articles = await API.get('/blog' + (value || searchURL));

        return articles.data;
    } catch (err) {
        return null;
    }
}