// import clsx from 'clsx'
// import { useRouteLoaderData, useLoaderData } from "react-router-dom";
import { useLoaderData } from "react-router-dom";
import { Article } from "features/blog";
import { Terminal } from "features/terminal";
import './home.scss'

export const Home = () => {
    // const user = useRouteLoaderData('root');
    const blog = useLoaderData();

    return (
        <div className='home'>
            <article className="layout__article">
                <div className='intro'>
                    <div className='intro__inner-wrapper'>
                        <h1 className='intro__title'>마신 <b>커피</b> 만큼 <b>코드</b>를 뽑아냅니다.</h1>
                        <h1 className='intro__title_md'>마신 <b>커피</b> 만큼<br /><b>코드</b>를 뽑아냅니다.</h1>
                        <h5 className='intro__subtitle'>Work harder, make it better, drink coffee: Coffee-Driven Development</h5>
                    </div>

                    <div className="intro__terminal"><Terminal /></div>
                </div>

                <div className='main'>
                    <h2 className='main__title'>
                        최근 게시물
                        <span className='main__text'>저의 생각들을 들여다보세요.</span>
                    </h2>

                    <ul className="articles">
                        {
                            blog?.data.map((data) =>
                                <li key={data.id}><Article articleData={data} /></li>)
                        }
                    </ul>
                </div>
            </article>
        </div>
    )
}