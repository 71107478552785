import { useMemo, useState } from 'react'

import {
    LOCAL_STORAGE_THEME_KEY,
    Theme,
    ThemeContext,
} from '../config/themeContext'

const defaultTheme =
    (localStorage.getItem(LOCAL_STORAGE_THEME_KEY)) || Theme.DARK
// console.log(defaultTheme)

export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState(defaultTheme)

    const defaultValue = useMemo(() => ({
        theme,
        setTheme,
    }),
        [theme, setTheme]
    )

    return (
        <ThemeContext.Provider value={defaultValue}>
            {children}
        </ThemeContext.Provider>
    )
}