// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  position: relative;
  width: 100%;
  min-height: 7rem;
  background: var(--footer-bg);
  color: var(--text-primary);
}
.footer__wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1280px;
  padding: 1rem 1.5rem;
}
.footer__col {
  display: flex;
  align-items: center;
}
.footer__header {
  font-size: 2.5rem;
  font-family: "Nasalization";
  cursor: pointer;
}
.footer__copyright {
  font-size: 1rem;
}
.footer__list li {
  display: flex;
  align-items: center;
  gap: 7px;
}
.footer__list li:not(:last-child) {
  margin-bottom: 7px;
}
.footer__list li a {
  color: var(--text-footer);
  font-size: 1.3rem;
}
.footer__list li a:hover {
  color: var(--primary-bg);
}

@media (max-width: 576px) {
  .footer__wrapper {
    flex-wrap: wrap;
    gap: 25px;
  }
}`, "",{"version":3,"sources":["webpack://./src/widgets/footer/ui/footer.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;EACA,WAAA;EACA,gBAAA;EACA,4BAAA;EACA,0BAAA;AADJ;AAGI;EACI,aAAA;EACA,8BAAA;EACA,WAAA;EACA,iBAAA;EACA,oBAAA;AADR;AAII;EACI,aAAA;EACA,mBAAA;AAFR;AAKI;EACI,iBAAA;EACA,2BAAA;EACA,eAAA;AAHR;AAMI;EACI,eAAA;AAJR;AAOI;EACI,aAAA;EACA,mBAAA;EACA,QAAA;AALR;AAOQ;EACI,kBAAA;AALZ;AASI;EACI,yBAAA;EACA,iBAAA;AAPR;AASQ;EACI,wBAAA;AAPZ;;AAYA;EAEQ;IACI,eAAA;IACA,SAAA;EAVV;AACF","sourcesContent":["@use 'src/app/styles/breakpoints';\n\n.footer {\n    position: relative;\n    width: 100%;\n    min-height: 7rem;\n    background: var(--footer-bg);\n    color: var(--text-primary);\n\n    &__wrapper {\n        display: flex;\n        justify-content: space-between;\n        width: 100%;\n        max-width: 1280px;\n        padding: 1rem 1.5rem;\n    }\n    \n    &__col {\n        display: flex;\n        align-items: center\n    }\n\n    &__header {\n        font-size: 2.5rem;\n        font-family: 'Nasalization';\n        cursor: pointer;\n    }\n\n    &__copyright {\n        font-size: 1rem;\n    }\n\n    &__list li {\n        display: flex;\n        align-items: center;\n        gap: 7px;\n\n        &:not(:last-child) {\n            margin-bottom: 7px;\n        }\n    }\n\n    &__list li a {\n        color: var(--text-footer);\n        font-size: 1.3rem;\n        \n        &:hover {\n            color: var(--primary-bg);\n        }\n    }\n}\n\n@media (max-width: breakpoints.$sm) {\n    .footer {\n        &__wrapper {\n            flex-wrap: wrap;\n            gap: 25px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
