import { API } from "shared/api";

export const editBlogArticle = async (articleData) => {
    try {
        await API.patch('/blog', articleData);
        
        return true;
    } catch (err) {

        return false;
    }
}