import { API } from "shared/api";

export const getPrevAndNextArticleCid = async (id) => {
    try {
        const articleCids = await API.get(`/blog/prevnnext/${id}`);
        
        return articleCids.data;
    } catch (err) {
        throw err;
    }
}