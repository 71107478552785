import { API } from "shared/api";
import { toast } from 'react-toastify';

export const postLogout = async () => {
    try {
        await API.post('/auth/logout');

        return true;
    } catch (err) {
        const message = err.response?.data?.msg ?? "알 수 없는 에러 발생";
        toast.error(message, { toastId: 'logout_false' });

        return false;
    }
}