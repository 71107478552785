import { Navigate, useNavigate, Outlet, useLocation, useRouteLoaderData } from "react-router-dom";
import { ROUTE_PERMISSIONS } from "shared/consts/routePermissions";

const ProtectedRoute = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const user = useRouteLoaderData('root');

  // redirect when no auth
  let redirectURL = pathname !== '/u/login' ? '?r=' + pathname : '';

  if (!user)
    return <Navigate to={'/u/login' + redirectURL} replace />

  // check permission is required?
  let permissionName = '';

  for (let routePerms of ROUTE_PERMISSIONS) {
    const linkArray = routePerms.link.split('/').slice(1);
    const currentPathArray = pathname.split('/').slice(1, routePerms.matchLevel + 1);

    // js can't check array equal with only == or ===
    const isArrayEquals = (a, b) => a.length === b.length && a.every((v, i) => v === b[i]);

    // check url match level split by /
    if (isArrayEquals(linkArray, currentPathArray)) {
      permissionName = routePerms.permissionName;
      break;
    }
  }

  const userPerms = user.permissions[permissionName];

  if (permissionName !== '' && userPerms !== undefined && !userPerms.read) {
    alert('페이지 열람 권한이 없습니다.');

    return navigate(-1);
  }


  return <Outlet />;
};

export default ProtectedRoute;