import axios from "axios";

export const API = axios.create({
    baseURL: "https://api.kinetic.moe/api",
    headers: {
        "Content-Type": "application/json",
    },
    withCredentials: true,
});

export const ImageAPI = axios.create({
    baseURL: "https://api.kinetic.moe/api",
    headers: {
        "Content-Type": 'multipart/form-data',
    },
    withCredentials: true,
});