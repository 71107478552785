// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout {
  background: var(--white);
}
.layout__wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.layout__content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
}
.layout__article {
  width: 100%;
  max-width: 1280px;
  flex-grow: 1;
  padding: 0 1.5rem;
}
.layout__footer {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
}
.layout__toggle-theme {
  position: fixed;
  bottom: 30px;
  right: 40px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  box-shadow: 0 0 0 1px var(--border);
  transition: 0.2s linear all;
  z-index: 10;
}
.layout__icon {
  top: 50%;
  transform: translate(0, -50%);
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.layout__toggle-theme.light {
  background-color: var(--primary-bg);
}
.layout__toggle-theme.dark {
  background-color: var(--black);
}
.layout__toast-container {
  top: 5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/layout/layout.scss"],"names":[],"mappings":"AAEA;EACI,wBAAA;AADJ;AAGI;EACI,aAAA;EACA,sBAAA;EACA,iBAAA;AADR;AAII;EACI,cAAA;EACA,aAAA;EACA,sBAAA;EACA,gBAAA;AAFR;AAKI;EACI,WAAA;EACA,iBAAA;EACA,YAAA;EACA,iBAAA;AAHR;AAMI;EACI,cAAA;EACA,aAAA;EACA,uBAAA;AAJR;AAOI;EACI,eAAA;EACA,YAAA;EACA,WAAA;EAEA,WAAA;EACA,YAAA;EAEA,kBAAA;EACA,mCAAA;EACA,2BAAA;EAEA,WAAA;AARR;AAWI;EACI,QAAA;EACA,6BAAA;EACA,cAAA;EACA,iBAAA;EACA,kBAAA;AATR;AAYI;EACI,mCAAA;AAVR;AAaI;EACI,8BAAA;AAXR;AAcI;EACI,SAAA;AAZR","sourcesContent":["@use 'src/app/styles/breakpoints';\n\n.layout {\n    background: var(--white);\n\n    &__wrapper {\n        display: flex;\n        flex-direction: column;\n        min-height: 100vh;\n    }\n\n    &__content {\n        flex: 1 0 auto;\n        display: flex;\n        flex-direction: column;\n        margin-top: 4rem;\n    }\n\n    &__article {\n        width: 100%;\n        max-width: 1280px;;\n        flex-grow: 1;\n        padding: 0 1.5rem;\n    }\n\n    &__footer {\n        flex: 0 0 auto;\n        display: flex;\n        justify-content: center\n    }\n\n    &__toggle-theme {\n        position: fixed;\n        bottom: 30px;\n        right: 40px;\n\n        width: 45px;\n        height: 45px;\n\n        border-radius: 50%;\n        box-shadow: 0 0 0 1px var(--border);\n        transition: 0.2s linear all;\n\n        z-index: 10;\n    }\n\n    &__icon {\n        top: 50%;\n        transform: translate(0, -50%);\n        display: block;\n        margin-left: auto;\n        margin-right: auto;\n    }\n\n    &__toggle-theme.light {\n        background-color: var(--primary-bg);\n    }\n\n    &__toggle-theme.dark {\n        background-color: var(--black);\n    }\n\n    &__toast-container {\n        top: 5rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
