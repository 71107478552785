import { API } from "shared/api";
import { toast } from 'react-toastify';

export const postLogin = async (loginData) => {
    try {
        await API.post('/auth/login', loginData);

        return true;
    } catch (err) {
        const message = err.response?.data?.msg ?? "알 수 없는 에러 발생";
        toast.error(message, { toastId: 'login_false' });

        return false;
    }
}