import { useEffect, useState } from 'react'
import { Link, NavLink, useRouteLoaderData, useLocation } from 'react-router-dom'
import { ReactComponent as LoginIcon } from 'shared/assets/icons/lock-outline.svg';
import { ReactComponent as MenuIcon } from 'shared/assets/icons/menu.svg';
import { ReactComponent as CloseIcon } from 'shared/assets/icons/close.svg';

import clsx from 'clsx'
import { CONTENT_NAVIGATION_MENU } from 'shared/consts'

import './header.scss'

export const Header = () => {
    const { pathname } = useLocation();
    const user = useRouteLoaderData('root');
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [redirectURL, setRedirectURL] = useState('');

    const getCurrentLink = () => {
        const restrictedPaths = ['/u/login', '/'];

        if (pathname && !restrictedPaths.includes(pathname))
            return '?r=' + pathname;

        return '';
    }

    useEffect(() => {
        const restrictedPaths = ['/u/login', '/'];

        if (pathname && !restrictedPaths.includes(pathname))
            setRedirectURL('?r=' + pathname);

    }, [pathname, redirectURL]);

    const navigationContent = CONTENT_NAVIGATION_MENU.map((item) => (
        <li key={item.title} className='header__navigation-menu-item'>
            <NavLink
                to={item.link}
                className={({ isActive }) =>
                    clsx('header__navigation-menu-link',
                        isActive && 'header__navigation-menu-link_active')
                }
                onClick={() => { setMenuOpen(false) }}
            >
                {item.title}
            </NavLink>
        </li>
    ));

    const LoginLink = (!user
        ? <NavLink
            className={({ isActive }) => clsx('header__menu_link', isActive && 'header__menu_link_active')}
            to={`/u/login${getCurrentLink()}`}
            onClick={() => { setMenuOpen(false) }}>
            <h2 className='header__menu_login'>LOGIN</h2>
        </NavLink>
        : <>
            <NavLink
                className={({ isActive }) => clsx('header__menu_link', isActive && 'header__menu_link_active')}
                to='/u/profile'
                onClick={() => { setMenuOpen(false) }}>
                <h2 className='header__menu_login'>PROFILE</h2>
            </NavLink>
            <img className='header__menu_img' src={user.image} alt={user.id} />
        </>
    );

    return (
        <header className='header'>
            <div className={clsx('header__container',
                isMenuOpen && 'header__conatiner_open')}>
                <Link className='header__title__wrapper' to='/' onClick={() => { setMenuOpen(false) }}>
                    <h2 className='header__title'>KINEWEB</h2>
                </Link>

                <div className='header__wrapper'>
                    <nav
                        className='header__navigation-menu'>
                        <div
                            className={clsx(
                                'header__navigation-menu-wrapper',
                                '_container'
                            )}>
                            <ul className='header__navigation-menu-list'>
                                {navigationContent}
                            </ul>
                        </div>
                    </nav>
                </div>

                {!user
                    ? <Link className='header__link' to={`/u/login${getCurrentLink()}`} onClick={() => { setMenuOpen(false) }}>
                        <LoginIcon className='header__login' />
                    </Link>
                    : <Link className='header__link' to='/u/profile' onClick={() => { setMenuOpen(false) }}>
                        <img className='header__user_img' src={user.image} alt={user.id} />
                    </Link>}
                    
                <div className='header__menu_wrapper'>
                    {isMenuOpen && LoginLink}
                </div>

                {!isMenuOpen
                    ? <MenuIcon className='header__icon_menu' onClick={() => { setMenuOpen(true) }} />
                    : <CloseIcon className='header__icon_close' onClick={() => { setMenuOpen(false) }} />}
            </div>
        </header>
    )
}