import './articleShare.scss'
import {
    EmailShareButton, FacebookShareButton, RedditShareButton, TelegramShareButton,
    TwitterShareButton, EmailIcon, FacebookIcon, RedditIcon,
    TelegramIcon, XIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from 'react-toastify';

export const ArticleShare = ({ link }) => {
    const CopyToClipboardToast = () => {
        toast.info('URL을 클립보드에 복사했습니다.', { toastId: 'copy_to_clipboard' });
    };

    return <div className="share__wrapper">
        <TwitterShareButton url={link}>
            <XIcon size={48} round={true} borderRadius={24}></XIcon>
        </TwitterShareButton>

        <FacebookShareButton url={link}>
            <FacebookIcon size={48} round={true} borderRadius={24}></FacebookIcon>
        </FacebookShareButton>

        <RedditShareButton url={link}>
            <RedditIcon size={48} round={true} borderRadius={24}></RedditIcon>
        </RedditShareButton>

        <TelegramShareButton url={link}>
            <TelegramIcon size={48} round={true} borderRadius={24}></TelegramIcon>
        </TelegramShareButton>

        <EmailShareButton url={link}>
            <EmailIcon size={48} round={true} borderRadius={24}></EmailIcon>
        </EmailShareButton>

        <CopyToClipboard text={link} onCopy={CopyToClipboardToast}>
            <button className="share__url_btn">URL</button>
        </CopyToClipboard>
    </div>
}