import { ImageAPI } from "shared/api";


export const UploadImage = async (dataTransfer) => {
    try {

        const files = [];
        for (let index = 0; index < dataTransfer.items.length; index++) {
            const file = dataTransfer.files.item(index);
            if (file) files.push(file);
        }

        const result = await Promise.all(
            files.map(async (file) => {
                const formData = new FormData();
                formData.append("image", file);

                const response = await ImageAPI.post('/image', formData)

                return response.status === 200 ? response.data : 'ServerError';
            })
        );

        return result;
    } catch (err) {
        throw err;
    }
}

// export const UploadImage = async (dataTransfer) => {
//     try {

//         const files = [];
//         for (let index = 0; index < dataTransfer.items.length; index++) {
//             const file = dataTransfer.files.item(index);
//             if (file) files.push(file);
//         }

//         const result = await Promise.all(
//             files.map(async (file) => {
//                 const formData = new FormData();
//                 formData.append("image", file);

//                 const response = await fetch("https://api.imgur.com/3/image/", {
//                     method: "POST",
//                     body: formData,
//                     headers: {
//                         Authorization: `Client-ID ${process.env.IMGUR_ID}`,
//                         Accept: "application/json",
//                     },
//                 });
//                 const resJson = await response.json();
                
//                 return resJson.status === 200 ? resJson.data.link : 'link';

//             })
//         );

//         return result;
//     } catch (err) {
//         throw err;
//     }
// }